.grid {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;
  width: $grid-width;
  max-width: $grid-max-width;
  margin-right: auto;
  margin-left: auto;

  &.-center {
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  &.-header {
    max-width: $grid-header-max-width;
  }

  &.-stretch {
    align-items: stretch;
  }

  &.-fluid {
    max-width: none;
  }

  &.-end {
    justify-content: flex-end;
  }

  &.-align-end {
    align-items: flex-end;
  }

  &.-align-center {
    align-items: center;
  }

  &.-pad {
    @include grid-gutter($grid-gutter);

    & > .grid__column {
      @include grid-gutter($grid-column-gutter);
    }
  }

  &.-noPadSelf {
    padding-right: 0;
    padding-left: 0;
  }

  &.-collapse {
    @include grid-collapse;
  }

  &.-mobile-reverse {
    flex-direction: column-reverse;

    @include breakpoint(map-get($breakpoints, portrait)) {
      flex-direction: row;
    }
  }

  &__column {
    width: 100%;
    align-self: flex-start;

    &.-noPad {
      padding-right: 0;
      padding-left: 0;
    }

    &.-center {
      align-items: center;
      justify-content: center;
    }

    &.-self-center {
      align-self: center;
    }

    &.-self-end {
      align-self: flex-end;
    }

    @each $breakpoint, $value in $breakpoints {
      &.-#{$breakpoint} {
        @include breakpoint($breakpoint) {
          @include column-generate;
        }
      }
    }
  }

  &__footer {
    &.-end {
      margin-left: auto;
    }
  }
}
