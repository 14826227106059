.form__legend {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.form__group {
  margin-bottom: rem(15px);
}

.form__label {
  display: block;
  margin-bottom: rem(5px);
  color: map-get($colors, blueKista);
  font-size: rem(13px);
  text-align: left;

  abbr {
    font-weight: bold;
    text-decoration: none;
  }
}

.form__input {
  @include title;
  display: block;
  width: 100%;
  padding: rem(12px) rem(22px);
  border: 0;
  border-radius: 0;
  line-height: 1;
  appearance: none;
  color: map-get($colors, blueKista);
  font-size: rem(16px);
  background-color: white;

  @include placeholder {
    color: map-get($colors, blueKista);
    opacity: 0.8;
  }

  @include breakpoint(map-get($breakpoints, portrait)) {
    font-size: rem(14px);
  }

  @include breakpoint(map-get($breakpoints, ipad)) {
    font-size: inherit;
  }
}

.form__submit {
  @include reset-button;
  @include title;
  display: block;
  padding: rem(12px) rem(15px);
  margin-left: auto;
  color: white;
  font-size: $base-font-size;
  background-color: map-get($colors, blueKista);
  transition: background-color 0.2s map-get($easings, appleOut);

  &:hover {
    background-color: lighten(map-get($colors, blueKista), 10);
  }
}

// added with JS
.form__error {
  @include title;
  padding: rem(20px) rem(20px);
  margin-bottom: rem(25px);
  list-style: square;
  list-style-position: inside;
  color: darken(map-get($colors, red), 25);
  text-align: left;
  background-color: lighten(map-get($colors, red), 35);

  & > li {
    padding-bottom: 5px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.form__note {
  @include title;
  margin-top: rem(10px);
  margin-bottom: rem(25px);
  color: map-get($colors, blueKista);
  font-size: rem(13px);
  text-align: left;
}
