$social-icon-spacing: rem(20px);
$social-icon-size: rem(32px);

.listSocial {
  margin-right: -$social-icon-spacing;
  margin-left: -$social-icon-spacing;

  &__item {
    display: inline-block;
    padding-left: $social-icon-spacing;
    padding-right: $social-icon-spacing;

    &:not(:last-child) {
      margin-right: -4px; // fix inline spacing
    }

    @include breakpoint(map-get($breakpoints, portrait)) {
      padding-left: $social-icon-spacing * 0.65;
      padding-right: $social-icon-spacing * 0.65;
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      padding-left: $social-icon-spacing;
      padding-right: $social-icon-spacing;
    }
  }

  &__link {
    position: relative;
    z-index: 0;
    color: map-get($colors, blueKista);
    transition: color 0.2s map-get($easings, appleOut);

    &:hover {
      color: lighten(map-get($colors, blueKista), 10);
    }

    // extend clickable area
    &::after {
      position: absolute;
      top: -15px;
      right: -10px;
      bottom: -10px;
      left: -10px;
      z-index: -1;
      content: '';
    }
  }

  .icon {
    width: $social-icon-size;
    height: $social-icon-size;

    @include breakpoint(map-get($breakpoints, portrait)) {
      width: $social-icon-size * 0.8;
      height: $social-icon-size * 0.8;
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      width: $social-icon-size;
      height: $social-icon-size;
    }
  }

  // list social in header
  &__container {
    .header & {
      max-width: rem(200px);
      padding-top: rem(30px);
      margin-top: rem(80px);
      margin-right: auto;
      margin-left: auto;
      border-top: rem(2px) solid map-get($colors, blueKista);
    }
  }
}

.listPartners {
  @include breakpoint(map-get($breakpoints, portrait)) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: rem(5px);
    margin-right: map-get($gap, small) * -1 * 2;
    margin-left: map-get($gap, small) * -1 * 2;
  }

  @include breakpoint(map-get($breakpoints, ipad)) {
    margin-right: map-get($gap, medium) * -1 * 2;
    margin-left: map-get($gap, medium) * -1 * 2;
  }

  &__item {
    margin-bottom: rem(75px);

    &:first-child {
      margin-top: rem(40px);

      .listPartners__img {
        width: rem(84px);
        height: rem(84px);
      }
    }

    &:last-child {
      margin-bottom: 0;

      .listPartners__img {
        width: rem(171px);
        height: rem(48px);
      }
    }

    @include breakpoint(map-get($breakpoints, portrait)) {
      padding-right: map-get($gap, small) * 2;
      padding-left: map-get($gap, small) * 2;
      margin-bottom: inherit;

      &:first-child {
        margin-top: inherit;
        margin-left: auto;
      }

      &:last-child {
        margin-right: auto;
      }
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      padding-right: map-get($gap, medium) * 2;
      padding-left: map-get($gap, medium) * 2;
    }
  }

  &__link {
    display: block;
  }
}

.listBullet {
  @media screen and (max-width: (map-get($breakpoints, portrait) - 1)) {
    .grid__column {
      &:not(:first-child) {
        display: none;
      }
    }
  }

  &__item {
    text-align: center;
  }

  &__icon {
    width: rem(88px);
    height: rem(88px);
    margin-bottom: rem(5px);

    &.green {
      color: map-get($colors, darkGreen);
    }
  }

  .title {
    font-size: rem(72px);
  }

  .abstract {
    font-size: rem(24px);
  }
}

.listPosts {
  text-align: left;

  &__item {
    display: flex;
    align-self: stretch;
    margin-bottom: 5rem;
  }

  &,
  .title {
    color: map-get($colors, darkGreen);
  }

  .title {
    line-height: 1.2;
    font-size: rem(32px);

    @media screen and (max-width: (map-get($breakpoints, portrait) - 1)) {
      margin-bottom: 0;
    }
  }

  &__link {
    display: block;
    width: 100%;
    font-size: 1.1rem;
    color: inherit;
    text-decoration: none;
    transition: background-color 0.2s map-get($easings, appleOut);
  }

  &__img {
    @extend %img-bg;
    padding-bottom: 55.5%;
    border-radius: 4px;
    background-color: map-get($colors, darkGreen);

    .listPosts__item.-video & {
      position: relative;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 0;
        height: 0;
        border-width: 60px 0 60px 110px;
        border-style: solid;
        border-color: transparent transparent transparent rgba(white, 0.75);
        transform: translate(-50%, -50%);
        transition: border-color 0.2s map-get($easings, appleOut);
        content: '';
      }
    }

    .listPosts__item.-video .listPosts__link:hover &::before {
      border-left-color: rgba(white, 0.9);
    }
  }

  &__content {
    @media screen and (max-width: (map-get($breakpoints, portrait) - 1)) {
      margin-bottom: 2rem;
    }

    @include breakpoint(map-get($breakpoints, portrait)) {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
    }
  }

  &__text {
    @include title;
    float: right;
    margin-bottom: rem(15px);
    font-size: rem(16px);
    line-height: 1.4;
  }

  .insert & {
    margin-top: rem(20px);
  }

  &__button {
    @media screen and (max-width: (map-get($breakpoints, portrait) - 1)) {
      justify-content: center;
    }
  }
}

.listTile {
  &__item {
    @include breakpoint(map-get($breakpoints, portrait)) {
      display: flex;
      flex-flow: row nowrap;
    }

    & > * {
      @include breakpoint(map-get($breakpoints, portrait)) {
        flex: 1 50%;
        max-width: 50%;
      }
    }

    &:nth-child(even) {
      // image {
      & > *:nth-child(1) {
        order: 2;
      }

      // content
      & > *:nth-child(2) {
        order: 1;

        @include breakpoint(map-get($breakpoints, portrait)) {
          padding-right: map-get($gap, medium);
          padding-left:
            (
              map-get($grid-gutter, iphone) + map-get($grid-gutter, portrait)
            );
        }

        @include breakpoint(map-get($breakpoints, ipad)) {
          padding-right: map-get($gap, large);
          padding-left: (map-get($grid-gutter, portrait) * 2);
        }

        // div
        & > * {
          @include breakpoint(map-get($breakpoints, portrait)) {
            margin-right: 0;
            margin-left: auto;
          }
        }
      }
    }
  }

  &__content {
    padding:
      (map-get($gap, small) * 2) (map-get($grid-gutter, iphone))
      (map-get($gap, medium));
    text-align: center;

    @include breakpoint(map-get($breakpoints, portrait)) {
      display: flex;
      flex-flow: column nowrap;
      min-height: rem(500px);
      min-height: 50vh;
      padding-left: map-get($gap, medium);
      padding-right:
        (
          map-get($grid-gutter, iphone) + map-get($grid-gutter, portrait)
        );
      text-align: inherit;
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      padding:
        map-get($gap, xlarge) (map-get($grid-gutter, portrait) * 2)
        map-get($gap, xlarge) map-get($gap, large);
    }

    // div
    & > * {
      max-width: rem(500px);
      margin: auto;

      @include breakpoint(map-get($breakpoints, portrait)) {
        max-width:
          rem(
            ($grid-max-width/2) - (map-get($gap, large) * 2) + (15px * 3)
          );
        margin-left: 0;
      }
    }

    .title.-h1 {
      margin-bottom: rem(20px);

      @media screen and (max-width: (map-get($breakpoints, macbook) - 1)) {
        font-size: rem(40px);
      }

      @media screen and (max-width: (map-get($breakpoints, ipad) - 1)) {
        font-size: rem(30px);
      }

      @media screen and (max-width: (map-get($breakpoints, ipad) - 1)) {
        font-size: rem(25px);
      }
    }
  }

  &__text {
    &:nth-child(odd) {
      @media screen and (max-width: (map-get($breakpoints, ipad) - 1)) {
        order: 1;
      }
    }
  }

  &__img {
    @extend %img-bg;
    position: relative;
    margin-bottom: rem(15px);
    background-color: map-get($colors, darkGreen);

    &--tall {
      height: rem(470px);

      @include breakpoint(map-get($breakpoints, ipad)) {
        height: rem(638px);
      }
    }

    &--square {
      // max-width: rem(466px);
      height: rem(343px);

      @include breakpoint(map-get($breakpoints, ipad)) {
        height: rem(466px);
      }
    }

    &.-coral {
      background-color: map-get($colors, coral);
    }

    &.-mint {
      background-color: map-get($colors, mint);
    }
  }
}

$feed-gap: rem(15px);

.listFeed {
  display: flex;
  flex-flow: row wrap;
  width: auto;
  margin-right: -$feed-gap;
  margin-left: -$feed-gap;

  @include breakpoint(map-get($breakpoints, portrait)) {
    margin-right: -$feed-gap * 1.6666666667;
    margin-left: -$feed-gap * 1.6666666667;
  }

  @include breakpoint(map-get($breakpoints, ipad)) {
    width: 100%;
    margin-right: -$feed-gap;
    margin-left: -$feed-gap;
    flex-wrap: nowrap;
  }

  &__container {
    text-align: center;

    @include breakpoint(map-get($breakpoints, ipad)) {
      display: flex;
      align-items: center;
    }
  }

  &__icon {
    width: rem(61px * 0.75);
    height: rem(61px * 0.75);
    margin-bottom: map-get($gap, medium);
    color: #f9ead5;

    @include breakpoint(map-get($breakpoints, ipad)) {
      min-width: rem(61px);
      min-height: rem(61px);
      margin-right: rem(70px);
      margin-bottom: 0;
      margin-left: rem(50px);
    }
  }

  &__item {
    flex: 1 50%;
    height: 50vw;
    height: calc(50vw - (#{$feed-gap} * 2));
    max-width: 50%;
    margin-bottom: $feed-gap;
    border-color: transparent;
    border-style: solid;
    border-left-width: $feed-gap;
    border-right-width: $feed-gap;

    @include breakpoint(map-get($breakpoints, portrait)) {
      flex: 1 25%;
      height: 25vw;
      height: calc(25vw - (#{$feed-gap} * 1.6666666667 * 2));
      max-width: 25%;
      margin-bottom: 0;
      border-left-width: $feed-gap * 1.6666666667;
      border-right-width: $feed-gap * 1.6666666667;
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      position: relative;
      border-left-width: $feed-gap;
      border-right-width: $feed-gap;
      height: 100%;

      &::after {
        display: block;
        height: 0;
        padding-bottom: 100%;
        content: '';
      }
    }

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }

  &__link {
    @include breakpoint(map-get($breakpoints, ipad)) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.listResidences {
  text-align: left;

  @media screen and (max-width: (map-get($breakpoints, portrait) - 1)) {
    margin-bottom: 2rem;
  }

  &__wrapper {
    &.grid {
      justify-content: flex-start;

      @include breakpoint(map-get($breakpoints, ipad)) {
        justify-content: center;
      }
    }
  }

  &__item {
    align-self: stretch;
  }

  &,
  .title {
    color: map-get($colors, darkGreen);
  }

  .title {
    margin-bottom: rem(15px);
    line-height: 1.2;
    font-size: rem(32px);
  }

  &__img {
    @extend %img-bg;
    width: 100%;
    height: rem(598px);
    padding-bottom: 55.5%;
    margin-bottom: rem(15px);

    @include breakpoint(map-get($breakpoints, portrait)) {
      height: rem(640px);
    }
  }

  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  &__text {
    @include title;
    margin-bottom: rem(20px);
    font-size: rem(16px);
    line-height: 1.4;
  }

  .insert & {
    margin-top: rem(20px);
  }
}
