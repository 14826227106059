@keyframes loading {
  from { opacity: 1; }
  to { opacity: 0; }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);

  .-loading & {
    display: block;
  }

  div {
    position: absolute;
    left: 19px;
    top: 0;
    width: 6px;
    height: 12px;
    border-radius: 40%;
    background: #deece7;
    animation: loading linear 1s infinite;
    transform-origin: 6px 25px;

    @for $i from 1 to 12 {
      &:nth-child(#{$i}) {
        transform: rotate(30deg * ($i));
        animation-delay: -1s + ($i * 5/60);
      }
    }
  }
}
