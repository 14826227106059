html {
  font-family: map-get($font, title);
  font-size: $base-font-size;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

b,
strong {
  font-weight: bold;
  line-height: inherit;
}

i,
em {
  font-style: italic;
  line-height: inherit;
}

h2 {
  @extend .title.-h2;
}

.title {
  @include title('medium');
  color: map-get($colors, darkGreen);

  &.-h1 {
    font-size: rem(36px);

    @include breakpoint(map-get($breakpoints, portrait)) {
      font-size: rem(40px);
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      font-size: rem(48px);
    }
  }

  &.-h2 {
    font-size: rem(32px);
    line-height: 36px;
    letter-spacing: -0.64px;

    @include breakpoint(map-get($breakpoints, portrait)) {
      font-size: rem(34px);
    }

    @include breakpoint(map-get($breakpoints, macbook)) {
      font-size: rem(48px);
      line-height: 56px;
      letter-spacing: -0.96px;
    }
  }

  &.-h3 {
    font-size: rem(24px);

    @include breakpoint(map-get($breakpoints, portrait)) {
      font-size: rem(28px);
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      font-size: rem(32px);
    }
  }

  &.-h4 {
    font-size: $base-font-size;
    font-weight: bold;
    color: map-get($colors, beige);

    @include breakpoint(map-get($breakpoints, portrait)) {
      font-size: rem(18px);
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      font-size: rem(20px);
    }
  }

  &.-center {
    text-align: center;
  }

  .highlight & {
    margin-bottom: rem(15px);
    color: map-get($colors, beige);
  }

  &.-blueKista {
    color: map-get($colors, blueKista);
  }

  &.-darkRed {
    color: map-get($colors, darkRed);
  }
}

.paragraph {
  margin-bottom: 2em;
  font-size: 1.6rem;
  line-height: 1.6em;

  &.-excerpt {
    font-size: 2rem;
  }
}

.abstract {
  @include title;
  margin-bottom: rem(15px);
  color: map-get($colors, darkGreen);
  font-size: rem(16px);
  line-height: 1.43;

  @include breakpoint(map-get($breakpoints, portrait)) {
    font-size: rem(16px);
    line-height: 1.5;
  }

  @include breakpoint(map-get($breakpoints, ipad)) {
    font-size: rem(18px);
  }

  .blogpost & {
    font-size: rem(20px);

    @media all and (max-width: map-get($breakpoints, iphone)) {
      font-weight: 300;
      font-size: rem(18px);
    }
  }

  .lightbox & {
    padding-bottom: rem(48px);

    @include breakpoint(map-get($breakpoints, portrait)) {
      padding-top: rem(10px);
    }
  }

  .highlight & {
    color: white;
  }

  &.-column {
    @include breakpoint(map-get($breakpoints, portrait)) {
      column-count: 2;
      column-gap: map-get($gap, small) * 2;
    }
  }

  &.-blueKista {
    color: map-get($colors, blueKista);
  }

  &.-darkRed {
    color: map-get($colors, darkRed);
  }
}

.-uppercase {
  text-transform: uppercase;
}

.link {
  position: relative;
  display: inline;
  flex: 1;
  color: currentColor;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    &::after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 100%;
      border: 1px solid currentColor;
      background-color: currentColor;
    }
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: -0.4rem;
    margin-bottom: 2px;
    vertical-align: middle;
  }

  &.-email {
    @include title;
    color: map-get($colors, beige);

    @include breakpoint(map-get($breakpoints, portrait)) {
      font-size: rem(14px);
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      font-size: inherit;
    }
  }

  &.-large {
    font-size: rem(20px);
  }

  &.-blueKista {
    color: map-get($colors, blueKista);
  }

  &.-darkRed {
    color: map-get($colors, darkRed);
  }

  &.-cookie {
    color: map-get($colors, beige);
  }
}

.fm {
  h2 {
    @include title('medium');
    margin-bottom: rem(15px);
    color: map-get($colors, blueKista);
    font-size: rem(25px);
    letter-spacing: rem(2px);

    &:not(:first-child) {
      margin-top: rem(25px);
    }
  }

  a {
    @extend .link;
    color: darken(map-get($colors, blueKista), 10);

    &:hover,
    &:focus {
      color: map-get($colors, blueKista);
    }
  }
}

.wp-content {
  padding-bottom: 6rem;

  h2,
  h3 {
    margin: 1.5rem 0 1rem;
  }

  h3 {
    @extend .title;
    @extend .-h3;
  }

  p {
    padding-top: rem(15px);
    font-size: 1.125rem;

    @include breakpoint(map-get($breakpoints, portrait)) {
      font-size: 1.25rem;
    }

    a {
      @extend .link;
      @extend .-blueKista;
    }
  }

  img {
    width: 100%;
  }

  a {
    @extend .link;
    @extend .-blueKista;
  }
}
