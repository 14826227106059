$lb: 'lightbox';

.#{$lb} {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($zIndex, ligthbox);
  display: none;
  overflow: scroll;
  text-align: center;
  background-color: rgba(map-get($colors, mediumGreen), 0.8);

  &::before {
    display: inline-block;
    margin-right: -6px; //-0.25em; /* Adjusts for spacing */
    height: 100%;
    vertical-align: middle;
    content: '';
  }

  @include breakpoint(map-get($breakpoints, portrait)) {
    padding: rem(30px) rem(15px);
  }

  &.-show {
    display: block;
  }

  &__dismiss {
    position: absolute;
    top: rem(15px);
    right: rem(28px);
    width: rem(44px);
    height: rem(44px);
    border-radius: 50%;
    border: rem(1px) solid map-get($colors, blueKista);
    background: transparent;
    transition: border-color 0.2s map-get($easings, appleOut);
    cursor: pointer;

    &:hover {
      border-color: darken(map-get($colors, blueKista), 10);

      &::after {
        color: darken(map-get($colors, blueKista), 10);
      }
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      color: map-get($colors, blueKista);
      font-size: rem(22px);
      line-height: rem(44px);
      text-align: center;
      content: '✕';
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    display: inline-block;
    max-width: 1024px;
    min-height: 300px;
    text-align: center;
    vertical-align: middle;
  }

  &.-video {
    .#{$lb}__content {
      max-width: 900px;
      width: 100%;
    }

    iframe {
      width: 100%;
    }
  }

  &.-newsletter {
    .lightbox__content {
      max-width: rem(555px);
      padding: rem(67px) rem(28px);
      background-color: map-get($colors, blue);

      @include breakpoint(map-get($breakpoints, portrait)) {
        padding: rem(70px) rem(40px);
      }

      @include breakpoint(map-get($breakpoints, ipad)) {
        padding: rem(77px) rem(50px);
      }
    }
  }
}
