$logoC-w: rem(79.5px * 2);
$logoC-h: rem(92.5px * 2);
$logoFull-w: rem(500px);
$logoFull-h: rem(500px);
$logoCpct-w: rem(14.4px);
$logoCpct-h: rem(35px);
$logo-gap: rem(24px);

$header-mobile-dimension: rem(55px);

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: map-get($zIndex, header);
  height: $header-mobile-dimension;
  background-color: rgba(map-get($colors, darkGreen), 1);
  backface-visibility: hidden;
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
  -ms-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;

  &__scrolled {
    background: rgb(68, 68, 68); /* IE */
    background: rgba(0, 0, 0, 0.78); /* NON-IE */
  }

  &__inner {
    display: flex;
    flex-flow: row nowrap;
  }

  .mainTitle {
    display: inline-block;
  }

  &.toggler {
    background-color: rgba(map-get($colors, darkGreen), 0);

    &.visible {
      background-color: rgba(map-get($colors, darkGreen), 1);

      .mainTitle {
        display: inline-block;
      }
    }
  }

  @include breakpoint(map-get($breakpoints, portrait)) {
    height: rem(65px);
  }

  @include breakpoint(map-get($breakpoints, ipad)) {
    height: rem(68px);
  }

  @include breakpoint(map-get($breakpoints, macbook)) {
    height: rem(70px);
  }
}

.mainTitle {
  position: absolute;
  top: 30%;
  left: 10%;
  display: none;
  font-size: rem(18px);
  text-decoration: none;
  color: map-get($colors, beige);
  transition: background-color 0.2s map-get($easings, appleOut);

  @include breakpoint(map-get($breakpoints, portrait)) {
    top: 35%;
    left: 10%;
    font-size: rem(20px);
  }

  &:hover {
    color: map-get($colors, lightGreen);
  }
}

.headerPaddingFix {
  padding-top: $header-mobile-dimension + rem(80px);

  @include breakpoint(map-get($breakpoints, portrait)) {
    padding-top: $logoC-h * 0.7 + $logo-gap * 0.8 + + rem(36px);
  }

  @include breakpoint(map-get($breakpoints, ipad)) {
    padding-top: $logoC-h * 0.85 + $logo-gap + rem(36px);
  }

  @include breakpoint(map-get($breakpoints, macbook)) {
    padding-top: $logoC-h + $logo-gap + rem(36px);
  }

  &.-less {
    padding-top: 9rem;
  }
}
