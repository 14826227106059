.nav {
  display: block;
  margin-right: auto;
  margin-left: auto;
  text-align: center;

  @media all and (max-width: (map-get($breakpoints, portrait)-1)) {
    display: block;

    & > li {
      @include title('medium');
      opacity: 0;
      transform: translateY(-30px);
      transition-property: opacity, transform;
      transition-duration: 0.2s;
      transition-timing-function: map-get($easings, appleOut);

      // what happens once menu button is clicked
      .nav__inner & {
        opacity: 1;
        transform: none;
        transition-duration: 0.4s;
        transition-delay: 0.6s;

        @for $i from 0 through 4 {
          &:nth-child(#{$i}) {
            transition-delay: #{0.15 * $i}s;
          }
        }
      }
    }
  }

  &__link {
    &:hover {
      color: map-get($colors, lightGreen);
    }
  }

  & > li {
    display: block;
    padding: rem(20px) rem(5px);
    color: map-get($colors, beige);
    font-size: rem(20px);
    text-align: left;

    @include breakpoint(map-get($breakpoints, portrait)) {
      margin-right: 7%;
      padding: rem(28px) rem(18px) rem(22px);
      min-width: rem(150px);
      text-align: center;
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      min-width: rem(200px);
    }

    @include breakpoint(map-get($breakpoints, macbook)) {
      min-width: rem(248px);
    }

    @include breakpoint(map-get($breakpoints, portrait)) {
      transition: background-color 0.2s map-get($easings, appleOut);
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      font-size: rem(32px);
    }

    a {
      color: currentColor;
      text-decoration: none;

      @include breakpoint(map-get($breakpoints, portrait)) {
        min-width: rem(150px);
      }

      @include breakpoint(map-get($breakpoints, ipad)) {
        min-width: rem(200px);
      }

      @include breakpoint(map-get($breakpoints, macbook)) {
        min-width: rem(248px);
      }
    }
  }
}

.nav__container {
  margin-right: auto;
  margin-left: auto;
  float: left;

  @include breakpoint(map-get($breakpoints, portrait)) {
    padding-left: $logoC-w * 0.7;
  }

  @include breakpoint(map-get($breakpoints, ipad)) {
    padding-left: $logoC-w * 0.85;
  }

  @include breakpoint(map-get($breakpoints, macbook)) {
    padding-left: $logoC-w;
    padding-right: rem(18px);
  }
}

.nav__inner {
  z-index: map-get($zIndex, nav);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  float: left;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: rem(80px) rem(15px) rem(44px);
  background-color: map-get($colors, darkGreen);
  opacity: 0;
  transform: translateX(100%);
  transition-property: opacity, transform;
  transition-duration: 0.2s;
  transition-timing-function: map-get($easings, appleOut);

  &.clicked {
    opacity: 0.98;
    transform: none;
  }
}

.navBtn {
  position: absolute;
  top: 30%;
  right: 10%;
  z-index: map-get($zIndex, hamburger);
  display: block;
  margin: 0;
  color: map-get($colors, beige);
  font-size: rem(18px);
  text-decoration: none;

  @include breakpoint(map-get($breakpoints, portrait)) {
    top: 35%;
    right: 10%;
    font-size: rem(20px);
  }

  &:hover {
    color: map-get($colors, lightGreen);
  }
}

.nav__footer {
  & > li,
  dd {
    display: block;
    color: map-get($colors, beige);
    font-size: rem(18px);

    @include breakpoint(map-get($breakpoints, portrait)) {
      min-width: rem(150px);
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      min-width: rem(200px);
    }

    @include breakpoint(map-get($breakpoints, macbook)) {
      min-width: rem(248px);
    }

    @include breakpoint(map-get($breakpoints, portrait)) {
      font-size: rem(14px);
      transition: background-color 0.2s map-get($easings, appleOut);
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      font-size: rem(16px);
    }

    @include breakpoint(map-get($breakpoints, macbook)) {
      font-size: rem(18px);
    }

    a {
      color: currentColor;
      text-decoration: none;

      @include breakpoint(map-get($breakpoints, portrait)) {
        min-width: rem(150px);
      }

      @include breakpoint(map-get($breakpoints, ipad)) {
        min-width: rem(200px);
      }

      @include breakpoint(map-get($breakpoints, macbook)) {
        min-width: rem(248px);
      }
    }
  }
}
