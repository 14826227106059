$directions: top bottom both;

.block {
  @each $dir in $directions {
    &__#{$dir} {
      @each $size, $value in $gap {
        &.-#{$size} {
          @if $dir == 'both' {
            padding-top: rem($value);
            padding-bottom: rem($value);
          } @else {
            padding-#{$dir}: rem($value);
          }
        }
      }
    }
  }

  // custom & tweaks
  .footer &__bottom {
    @media all and (max-width: (map-get($breakpoints, portrait) - 1)) {
      padding-bottom: rem(50px);
    }
  }

  &__both.insert {
    @media all and (max-width: (map-get($breakpoints, portrait) - 1)) {
      padding-top: rem(100px);
      padding-bottom: rem(100px);
    }
  }

  &.-front {
    padding-top: rem(20px);
  }
}

// block with colors
.block__color {
  &.-beige {
    background-color: map-get($colors, beige);

    &.-hover {
      &:hover,
      &:focus {
        background-color: lighten(map-get($colors, beige), 10);
      }
    }
  }

  &.-blue {
    background-color: map-get($colors, blue);

    &.-hover {
      &:hover,
      &:focus {
        background-color: lighten(map-get($colors, blue), 10);
      }
    }
  }

  &.-coralLight {
    background-color: map-get($colors, coralLight);

    &.-hover {
      &:hover,
      &:focus {
        background-color: lighten(map-get($colors, coralLight), 10);
      }
    }
  }

  &.-purple {
    background-color: map-get($colors, purple);

    &.-hover {
      &:hover,
      &:focus {
        background-color: lighten(map-get($colors, purple), 10);
      }
    }
  }

  &.-turquoise {
    background-color: map-get($colors, turquoise);

    &.-hover {
      &:hover,
      &:focus {
        background-color: lighten(map-get($colors, turquoise), 10);
      }
    }
  }

  &.-emerald {
    background-color: map-get($colors, emerald);

    &.-hover {
      &:hover,
      &:focus {
        background-color: lighten(map-get($colors, emerald), 5);
      }
    }
  }

  &.-grey {
    background-color: #eaeaea;
  }

  &.-green {
    background-color: map-get($colors, green);

    &.-hover {
      &:hover,
      &:focus {
        background-color: lighten(map-get($colors, green), 10);
      }
    }
  }

  &.-lightGreen {
    background-color: map-get($colors, lightGreen);

    &.-hover {
      &:hover,
      &:focus {
        background-color: lighten(map-get($colors, lightGreen), 10);
      }
    }
  }

  &.-mediumGreen {
    background-color: map-get($colors, mediumGreen);

    &.-hover {
      &:hover,
      &:focus {
        background-color: lighten(map-get($colors, mediumGreen), 10);
      }
    }
  }

  &.-darkGreen {
    background-color: map-get($colors, darkGreen);

    &.-hover {
      &:hover,
      &:focus {
        background-color: lighten(map-get($colors, darkGreen), 10);
      }
    }
  }

  &.-orange {
    background-color: map-get($colors, orange);

    &.-hover {
      &:hover,
      &:focus {
        background-color: lighten(map-get($colors, orange), 10);
      }
    }
  }

  &.-blueKista {
    background-color: map-get($colors, blueKista);

    &.-hover {
      &:hover,
      &:focus {
        background-color: lighten(map-get($colors, blueKista), 10);
      }
    }
  }

  &.-white {
    background-color: map-get($colors, white);

    &.-hover {
      &:hover,
      &:focus {
        background-color: lighten(map-get($colors, white), 10);
      }
    }
  }

  &.-mint {
    background-color: map-get($colors, mint);

    &.-hover {
      &:hover,
      &:focus {
        background-color: lighten(map-get($colors, mint), 10);
      }
    }
  }
}
