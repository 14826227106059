*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  min-width: 320px;
  cursor: default;

  &::after {
    display: none;

    @each $breakpoint, $value in $breakpoints {
      @include breakpoint($breakpoint) {
        content: '#{$breakpoint}';
      }
    }
  }
}

img {
  display: inline-block;
  vertical-align: middle;
  height: auto;
  max-width: 100%;
}

%img-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

:focus {
  outline: dotted thin;
}

:hover,
:active {
  outline: 0;
}

::selection {
  color: map-get($colors, beige);
  background-color: map-get($colors, mediumGreen);
}

.-shadow {
  filter: drop-shadow(0 rem(1px) rem(2px) rgba(black, 0.65));
}

.-textCenter {
  text-align: center;
}

.-parallax {
  background-attachment: fixed;
  backface-visibility: hidden;

  & ~ * {
    position: relative;
    z-index: 1;
  }
}

// Margins
@include list-loop('.mt--', 'margin-top');
@include list-loop('.mr--', 'margin-right');
@include list-loop('.mb--', 'margin-bottom');
@include list-loop('.ml--', 'margin-left');
@include list-loop('.mx--', 'margin');

// Paddings
@include list-loop('.pt--', 'padding-top');
@include list-loop('.pr--', 'padding-right');
@include list-loop('.pb--', 'padding-bottom');
@include list-loop('.pl--', 'padding-left');
@include list-loop('.px--', 'padding');
