@mixin reset-button {
  display: inline-block;
  vertical-align: top;
  outline: none;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  font-family: map-get($font, title);
  line-height: 1em;
  text-align: center;
  appearance: none;
  background: transparent;
  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

@mixin reset-input {
  padding: 0;
  border: 0;
  border-radius: 0;
  font-family: map-get($font, body);
  line-height: 1em;
  appearance: none;
  background: transparent;

  &:focus {
    outline: none;
  }

  @at-root textarea#{&} {
    resize: vertical;
  }

  &[type='search'] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
}

@mixin clearfix {
  &::before,
  &::after {
    content: ' ';
    display: table;
    flex-basis: 0;
  }

  &::after {
    clear: both;
  }
}

@mixin breakpoint($value) {
  $bp: nth($value, 1);
  $dir: if(length($value) > 1, nth($value, 2), up);
  $media-query: if($dir == 'down', max-width, min-width);

  @if type-of($bp) == 'number' and unit($bp) == 'px' {
    @media only screen and (#{$media-query}: $bp) {
      @content;
    }
  } @else if map-has-key($tweakpoints, $bp) {
    @media only screen and (#{$media-query}: map-get($tweakpoints, $bp)) {
      @content;
    }
  } @else if map-has-key($breakpoints, $bp) {
    @media only screen and (#{$media-query}: map-get($breakpoints, $bp)) {
      @content;
    }
  } @else {
    @warn 'Could not find #{value} in $tweakpoints or $breakpoints.';
  }
}

@mixin column($value: $grid-columns) {
  @extend .grid__column;

  $val: nth($value, 1);
  $columnWidth: if(
    length($value) > 2,
    100 / nth($value, 3),
    $grid-columns-width
  );

  @if unitless($val) {
    width: #{$columnWidth * $val + '%'};
  } @else if type-of($val) == 'number' and unit($val) == '%' {
    width: $val;
  } @else {
    @warn 'The value "#{$value}" passed to grid-column mixin is invalid.';
  }
}

@mixin column-offset($value: 1) {
  $val: nth($value, 1);
  $columnWidth: if(
    length($value) > 2,
    100 / nth($value, 3),
    $grid-columns-width
  );
  margin-left: #{$columnWidth * $val + '%'};
}

@mixin grid-gutter($map) {
  @each $breakpoint, $value in $breakpoints {
    @include breakpoint($breakpoint) {
      padding-right: map-get($map, $breakpoint);
      padding-left: map-get($map, $breakpoint);
    }
  }
}

@mixin column-generate {
  @for $i from 1 through $grid-columns {
    &-#{$i} {
      width: #{$i * $grid-columns-width + '%'};
    }
  }
}

@mixin grid-collapse {
  @each $breakpoint, $value in $breakpoints {
    @include breakpoint($breakpoint) {
      $offset: (
        map-get($grid-gutter, $breakpoint) +
        map-get($grid-column-gutter, $breakpoint)
      );
      margin-right: -$offset;
      margin-left: -$offset;
      width: calc(#{$grid-width} + #{$offset * 2});
    }
  }
}

@mixin aspect-ratio($x, $y) {
  $padding: #{($y / $x) * 100 + '%'};
  position: relative;
  padding-top: $padding;
  width: 100%;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

@mixin title($weight: 400, $style: normal) {
  font-family: map-get($font, title);

  @if $weight == 'thin' or $weight == 'light' or $weight == 300 {
    font-weight: 300;
  }

  @if $weight == 'normal' or $weight == 'regular' or $weight == 400 {
    font-weight: 400;
  } @else if $weight == 500 or $weight == 'medium' {
    font-weight: 500;
  }
}

// Convert px value to em equivalent
// @param $value Input px value
// @param $px-reference Reference px size (font-size of self/parent)
@function em($value, $px-reference: $base-font-size) {
  @return ($value / $px-reference) * 1em;
}

@function rem($value, $px-reference: $base-font-size) {
  @return ($value / $px-reference) * 1rem;
}

// scss-lint:disable VendorPrefix
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin list-loop($className, $styleName) {
  $i: 0;

  @while $i <= 10 {
    #{$className}#{$i} {
      #{$styleName}: #{$i + if($i > 0, 'rem', '')};
    }

    @each $breakpoint, $value in $breakpoints {
      @include breakpoint($breakpoint) {
        #{$className}#{$breakpoint}-#{$i} {
          #{$styleName}: #{$i + if($i > 0, 'rem', '')} !important;
        }
      }
    }
    $i: $i + 1;
  }
}
