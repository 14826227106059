.cookie {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($zIndex, cookie);
  padding-top: rem(10px);
  padding-bottom: rem(10px);
  text-align: center;
  background-color: map-get($colors, darkGreen);

  @include breakpoint(map-get($breakpoints, portrait)) {
    text-align: inherit;
  }

  &__content {
    @include breakpoint(map-get($breakpoints, portrait)) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
    }
  }

  &__text {
    @include title;
    margin-bottom: rem(21px);
    color: white;
    font-size: rem(14px);

    @include breakpoint(map-get($breakpoints, portrait)) {
      margin-bottom: rem(15px);
    }

    a {
      text-decoration: underline;
    }

    @include breakpoint(map-get($breakpoints, portrait)) {
      width: 75%;
      margin-bottom: inherit;
      font-size: rem(16px);
    }
  }
}
