.button {
  @include reset-button;
  padding: rem(40px);
  color: map-get($colors, darkGreen);
  font-size: rem(18px);
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  transition-property: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: map-get($easings, appleOut);

  @include breakpoint(map-get($breakpoints, portrait)) {
    min-width: rem(260px);
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &.-primary {
    color: white;
    background-color: map-get($colors, blueKista);

    &:hover,
    &:focus {
      background-color: lighten(map-get($colors, blueKista), 10);
    }
  }

  &.-secondary {
    color: map-get($colors, blueKista);
    background-color: map-get($colors, coral);

    &:hover,
    &:focus {
      background-color: darken(map-get($colors, coral), 5);
    }
  }

  &.-tertiary {
    border-style: solid;
    border-color: map-get($colors, blueKista);
    color: white;
    background-color: map-get($colors, mediumGreen);

    &:hover,
    &:focus {
      background-color: lighten(map-get($colors, lightGreen), 5);
    }
  }

  &.-block {
    width: 100%;
    min-width: 0;
  }

  &.-long {
    width: 100%;

    @include breakpoint(map-get($breakpoints, portrait)) {
      max-width: rem(557px);
    }
  }

  &__fullBg {
    overflow: hidden;
    text-align: center;
    background-color: map-get($colors, blueKista);

    @include breakpoint(map-get($breakpoints, portrait)) {
      height: map-get($gap, xlarge);
      overflow: visible;
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      height: map-get($gap, xxlarge);
    }

    .button {
      @include breakpoint(map-get($breakpoints, portrait)) {
        transform: translateY(-50%);
      }
    }
  }

  .hero & {
    display: block;
    padding-right: rem(10px);
    padding-left: rem(10px);

    @include breakpoint(map-get($breakpoints, portrait)) {
      padding-right: 0;
      padding-left: 0;
    }

    &__group {
      @include breakpoint(map-get($breakpoints, portrait)) {
        display: flex;
        flex-flow: row wrap;
      }

      &.-center {
        @include breakpoint(map-get($breakpoints, portrait)) {
          width: fit-content;
          justify-content: center;

          // firefox
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .cookie & {
    min-width: 0;
    width: 80%;
    padding: rem(15px) rem(25px);
    margin-right: 0;
    margin-left: auto;
    border-radius: 5px;
    font-size: rem(14px);
    font-weight: bold;

    @include breakpoint(map-get($breakpoints, portrait)) {
      width: 300px;
      font-size: rem(16px);
    }
  }

  &.-news {
    min-width: initial;
    padding: 0.825rem 1.25rem;
    border-radius: 25px;
    margin-bottom: rem(15px);
    font-size: rem(20px);
    background-color: map-get($colors, lightGrey);

    &:hover {
      color: white;
      background-color: map-get($colors, mediumGreen);
    }
  }
}

.done {
  display: none;
}
