// could have done this with a loop but I had some issue with SASS…
// some keys in the array were not taken into account and by this day, I still don't know why.
.inherit {
  &.-turquoise {
    .inherit__color {
      color: map-get($colors, turquoise);
    }
  }

  &.-salmon {
    .inherit__color {
      color: map-get($colors, salmon);
    }
  }

  &.-purpleLight {
    .inherit__color {
      color: map-get($colors, purpleLight);
    }
  }
}
