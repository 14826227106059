$logoFull-w: rem(300px);
$logoFull-h: rem(300px);
$logo-gap: rem(24px);
$title-font-size: 1.9em;

.hero {
  @extend %img-bg;
  z-index: map-get($zIndex, hero);
  height: 120vh;
  min-height: 100%;
  padding: ($header-mobile-dimension + rem(45px)) rem(50px) rem(100px);
  text-align: center;
  background-color: rgba(map-get($colors, mediumGreen), 1);
  background-blend-mode: multiply;

  @include breakpoint(map-get($breakpoints, portrait)) {
    padding-top: $logoC-h * 0.7 + $logo-gap * 0.8;
  }

  @include breakpoint(map-get($breakpoints, ipad)) {
    padding-top: $logoC-h * 0.85 + $logo-gap;
  }

  @include breakpoint(map-get($breakpoints, macbook)) {
    padding-top: $logoC-h + $logo-gap;
  }

  &__content {
    margin: auto;
    margin-top: rem(50px);
    margin-bottom: 0;
  }

  &__wLimit {
    max-width: rem(640px);
    margin-right: auto;
    margin-left: auto;

    .home & {
      max-width: rem(600px);
    }
  }

  &.-arrow {
    &::after {
      position: absolute;
      left: 50%;
      bottom: -4rem;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid map-get($colors, beige);
      transform: translateX(-50%);
      content: '';
      width: 15px;

      @include breakpoint(map-get($breakpoints, iphone)) {
        bottom: 2rem;
      }
    }
  }

  // main icon in hero
  .icon {
    height: $logoFull-h;
    width: $logoFull-w;
    margin-bottom: 15px;

    @include breakpoint(map-get($breakpoints, portrait)) {
      height: $logoFull-h * 1.2 + $logo-gap * 1.2;
      width: $logoFull-h * 1.2 + $logo-gap * 1.2;
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      height: $logoFull-h * 1.35 + $logo-gap * 1.35;
      width: $logoFull-h * 1.35 + $logo-gap * 1.35;
    }

    @include breakpoint(map-get($breakpoints, macbook)) {
      height: $logoFull-h * 1.5 + $logo-gap * 1.5;
      width: $logoFull-h * 1.5 + $logo-gap * 1.5;
    }
  }

  .title {
    font-size: $title-font-size;

    @include breakpoint(map-get($breakpoints, portrait)) {
      font-size: $title-font-size * 1.3;
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      font-size: $title-font-size * 1.45;
    }

    @include breakpoint(map-get($breakpoints, macbook)) {
      font-size: $title-font-size * 1.6;
    }
  }
}
