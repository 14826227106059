.map {
  height: 100vh;
  width: 100%;
  min-height: rem(350px);
  max-height: rem(500px);

  @include breakpoint(map-get($breakpoints, portrait)) {
    min-height: rem(550px);
    max-height: rem(800px);
  }

  .listTile__img & {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: inherit;
    min-height: inherit;
    max-height: inherit;
  }
}

.frontpage-map {
  &__signs {
    @include breakpoint(map-get($breakpoints, portrait)) {
      align-self: flex-end;
    }
  }
}
