$logoFull-w: rem(150px);
$logoFull-h: rem(150px);
$logo-gap: rem(24px);

.footer {
  overflow: hidden;
  padding:
    map-get($gap, medium) (map-get($gap, small) * 2)
    (map-get($gap, small) * 2);
  text-align: left;
  background-color: map-get($colors, darkGreen);

  @include breakpoint(map-get($breakpoints, portrait)) {
    padding: map-get($gap, xlarge) map-get($gap, medium);
  }

  .icon {
    height: 5rem;
    width: 5rem;

    @include breakpoint(map-get($breakpoints, iphone)) {
      height: $logoFull-h;
      width: $logoFull-w;
      margin-left: -15px;
    }

    &.-partners {
      margin-left: auto;
    }

    &.-skanska {
      height: 1rem;
      color: white;

      @include breakpoint(map-get($breakpoints, iphone)) {
        height: 1.7rem;
      }
    }

    &.-small {
      height: 5rem;
      width: 5rem;
      margin-right: 15px;

      @include breakpoint(map-get($breakpoints, iphone)) {
        height: 7.375rem;
        width: 7.375rem;
        margin-right: 20px;
      }
    }
  }

  .image {
    height: 100%;
    display: flex;
    flex-flow: row;
    align-items: flex-end;

    &.-grayscale {
      filter: brightness(2.5) grayscale(100);
    }
  }
}
