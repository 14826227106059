.insert {
  &.-background {
    @extend %img-bg;
    padding-top: map-get($gap, large);
    padding-bottom: map-get($gap, large);

    @include breakpoint(map-get($breakpoints, portrait)) {
      padding-bottom: map-get($gap, xxlarge);
    }
  }

  &.-post {
    padding-top: map-get($gap, large);
    padding-bottom: map-get($gap, large);

    @include breakpoint(map-get($breakpoints, portrait)) {
      padding-top: map-get($gap, xlarge);
      padding-bottom: map-get($gap, xlarge);
    }

    &.-no-pt {
      padding-top: 0;
    }

    .page-template-archive & {
      padding-top: map-get($gap, medium);
    }
  }

  &.-icon {
    position: relative;
    overflow: hidden;
    background-color: map-get($colors, mint);
  }

  &.-offsetBanner {
    position: relative;
    padding-top: 0 !important;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: block;
      height: map-get($gap, large);
      content: '';

      .block__color.-grey + & {
        background-color: map-get($colors, grey);
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 25%;
    fill: map-get($colors, mediumGreen);
    width: rem(390px * 2);
    height: rem(588px * 2);
    opacity: 0.06;
    transform: translate(0, -50%);

    @include breakpoint(map-get($breakpoints, portrait)) {
      right: inherit;
      left: -50%;
    }

    @include breakpoint(map-get($breakpoints, ipad)) {
      left: -12.5%;
    }
  }

  &__content {
    text-align: left;

    .title {
      margin-bottom: rem(20px);
    }
  }
}
