.blogpost {
  margin-bottom: 3rem;

  &__text {
    @include title('light');
    margin-top: 2rem;
    color: map-get($colors, darkGreen);
    font-size: rem(16px);
    line-height: 1.6;

    @include breakpoint(map-get($breakpoints, portrait)) {
      font-size: rem(18px);
    }

    p {
      padding-top: rem(15px);
      font-size: 1.125rem;

      @include breakpoint(map-get($breakpoints, portrait)) {
        font-size: 1.25rem;
      }
    }

    img {
      width: 100%;
    }
  }

  &__image {
    object-fit: cover;
    margin: 6rem 0;
    height: 20rem;
    width: 100%;

    @include breakpoint(map-get($breakpoints, portrait)) {
      height: 57rem;
    }
  }

  &__video {
    position: relative;
    height: 0;
    margin-top: rem(2 * map-get($gap, small)); // 2* to compensate the padding
    padding-bottom: 56.25%; /* 16:9 */

    iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .wp-block-media-text {
    p {
      font-size: 1rem;
    }

    img {
      object-fit: cover;
      width: 466px;
      height: 466px;
    }

    &.has-media-on-the-right {
      .wp-block-media-text__media {
        margin-left: auto;
      }

      .wp-block-media-text__content {
        padding-left: 0;
      }
    }
  }
}

.wp-block-image {
  margin-top: 2rem;
}
