// Colors
$colors: (
  black: #000000,
  white: #ffffff,
  gray900: #212121,
  gray800: #424242,
  gray700: #616161,
  gray600: #757575,
  gray500: #9e9e9e,
  gray400: #bdbdbd,
  gray300: #e0e0e0,
  gray200: #f5f5f5,
  gray100: #fafafa,
  red: #e74141,
  // blue: #4196e7,
    // orange: #ff5825,
    yellow: #ffcd00,
  // kista
    blue: #deece7,
  blueLight: #d8dfdd,
  blueGrey: #c8d7d2,
  blueKista: #2d5564,
  turquoise: #82aaaa,
  emerald: #007d7d,
  purple: #78738c,
  purpleLight: #c3becd,
  coral: #fad2c3,
  coralLight: #ebdcc8,
  orange: #be7328,
  green: #468250,
  salmon: #f0aa96,
  grey: #eaeaea,
  // kista-2021 - removed duplicates from kista
    lightGreen: #5a8250,
  mediumGreen: #435f43,
  darkGreen: #343d2c,
  imageOverlay: #003200,
  darkRed: #63392d,
  beige: #faf7f0,
  mint: #c8d7d2,
  lightGrey: #343d2c1a,
);

// Font
$font: (
  body: #{'Circular Std',
  Helvetica,
  Arial,
  sans-serif},
  title: #{'Circular Std Book',
  Helvetica,
  Arial,
  sans-serif},
);

$base-font-size: 16px;

// Breakpoints
$breakpoints: (
  start: 0,
  iphone: 641px,
  portrait: 769px,
  ipad: 1025px,
  macbook: 1281px,
  imac: 1441px,
);

// Tweakpoints
$tweakpoints: ();

// Grid
$grid-width: 100%;
$grid-max-width: 1138px + 60px; // 1260px;
$grid-header-max-width: 1328px;
$grid-columns: 12;
$grid-columns-width: (100 / $grid-columns) !default;

$grid-gutter: (
  start: 10px,
  imac: 15px,
  macbook: 15px,
  ipad: 15px,
  portrait: 15px,
  iphone: 10px,
);

$grid-column-gutter: (
  start: 5px,
  imac: 15px,
  macbook: 15px,
  ipad: 15px,
  portrait: 10px,
  iphone: 5px,
);

// Spacing
$gap: (
  small: 15px,
  medium: 50px,
  large: 75px,
  xlarge: 100px,
  xxlarge: 120px,
);

// Z-index
$zIndex: (
  cookie: 9999,
  ligthbox: 9000,
  header: 8001,
  nav: 8002,
  hamburger: 8003,
  logo: 8004,
  newsletter: 7000,
);

// Easings
$easings: (
  // In
    easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53),
  easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19),
  easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22),
  easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06),
  easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715),
  easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035),
  easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335),
  easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045),
  // Out appleOut: cubic-bezier(0.4, 0.01, 0.165, 0.99),
  swiftOut: cubic-bezier(0.55, 0, 0.1, 1),
  easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94),
  easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1),
  easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1),
  easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1),
  easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1),
  easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1),
  easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1),
  easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275),
  // In out easeInOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94),
  easeInOutSine: cubic-bezier(0.39, 0.575, 0.565, 1),
  easeInOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1),
  easeInOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1),
  easeInOutQuint: cubic-bezier(0.23, 1, 0.32, 1),
  easeInOutExpo: cubic-bezier(0.19, 1, 0.22, 1),
  easeInOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1),
  easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55)
);
