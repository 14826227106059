.carousel {
  margin: 6rem 0;
  height: 20rem;

  @include breakpoint(map-get($breakpoints, portrait)) {
    height: 40%;
  }

  @include breakpoint(map-get($breakpoints, ipad)) {
    height: 47rem;
  }

  &__cell {
    width: 100%;
    height: 100%;
    background: #ffffff;
  }

  &__image {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.4s;
  }

  .carousel__image {
    &.flickity-lazyloaded {
      opacity: 1;
    }
  }
}

.flickity {
  &-page-dots {
    bottom: -2rem;

    .dot {
      width: 15px;
      height: 15px;
      background-color: map-get($colors, darkGreen);
    }
  }
}
