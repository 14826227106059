// Margin/spacing util
.m {
  $directions: (
    top: t,
    right: r,
    bottom: b,
    left: l,
  );

  // Spacings
  $spacings: (
    0: 0,
    1: 4,
    2: 8,
    3: 12,
    4: 16,
    5: 24,
    6: 32,
    7: 40,
    8: 48,
    9: 56,
    10: 64,
    11: 72,
    12: 80,
  );

  $other: (
    auto: auto,
  );

  @each $breakpoint, $_ in $breakpoints {
    @each $index, $value in $spacings {
      @each $direction-long, $direction-short in $directions {
        &#{$direction-short}-#{$breakpoint}-#{$index} {
          @include breakpoint($breakpoint) {
            margin-#{$direction-long}: #{$value / 10}rem;
          }
        }
      }
    }

    @each $index, $value in $other {
      @each $direction-long, $direction-short in $directions {
        &#{$direction-short}-#{$breakpoint}-#{$index} {
          @include breakpoint($breakpoint) {
            margin-#{$direction-long}: #{$value};
          }
        }
      }
    }
  }
}
