$iconC-size: rem(62px);
$iconC-size-mobile: rem(48px);
$icon-w: rem(30px);
$icon-h: rem(20px);

.newsletterCTA {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: map-get($zIndex, newsletter);
  width: $iconC-size-mobile;
  height: $iconC-size-mobile;
  color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  background-color: map-get($colors, orange);
  transform: translateY(-50%);
  transition: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: map-get($easings, appleOut);
  cursor: pointer;

  &:hover {
    color: map-get($colors, orange);
    background-color: white;
  }

  @include breakpoint(map-get($breakpoints, portrait)) {
    width: $iconC-size;
    height: $iconC-size;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $icon-w;
    height: $icon-h;
    transform: translate(-50%, -50%);
  }
}
