@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes check {
  0% {
    width: 0;
    height: 0;
  }

  50% {
    height: rem(8px);
    width: 0;
  }

  100% {
    width: rem(15px);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
