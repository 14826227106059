.progress {
  position: relative;
  align-items: center;
  margin-top: map-get($gap, medium);
  margin-bottom: map-get($gap, medium);
  text-align: center;

  @include breakpoint(map-get($breakpoints, portrait)) {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: map-get($gap, large);
  }

  &__item {
    @include title;
    position: relative;
    padding-top: rem(25px);
    padding-bottom: rem(25px);
    color: map-get($colors, blueKista);
    text-transform: uppercase;
    animation: fadeInLeft 0.8s map-get($easings, appleOut);
    animation-fill-mode: backwards;

    @include breakpoint(map-get($breakpoints, portrait)) {
      flex: 0 auto;
      padding: 0 rem(20px);
      margin: 0 rem(20px);
      font-size: rem(20px);
      letter-spacing: 0.05em;

      &:first-child {
        margin-left: auto;
      }

      &:last-child {
        margin-right: auto;
      }
    }

    .highlight & {
      color: white;
    }

    &:first-child {
      padding-top: 0;
      margin-left: auto;
    }

    &:last-child {
      padding-bottom: 0;
      margin-right: auto;

      &::after {
        content: none;
      }
    }

    @for $i from 1 through 8 {
      &:nth-child(#{$i}) {
        animation-delay: ($i * 400ms);
        //icon
        &::before {
          animation-delay: (($i * 400ms) + 800ms);
        }
      }
    }

    &.-active {
      font-weight: 500;
      color: map-get($colors, blueKista);

      .highlight & {
        color: white;
      }
    }

    &.-active ~ &.-inactive {
      color: map-get($colors, gray500);

      &::before {
        content: none;
      }

      &::after {
        border-top-color: map-get($colors, gray500);

        @include breakpoint(map-get($breakpoints, portrait)) {
          border-top-color: transparent;
          border-left-color: map-get($colors, gray500);
        }
      }
    }

    // icon
    &::before {
      top: 0;
      left: 50%;
      z-index: 1;
      display: inline-block;
      width: rem(15px);
      height: rem(8px);
      border-width: 0 0 rem(3px) rem(3px);
      border-style: solid;
      border-color: map-get($colors, green);
      transform: translate(-50%, -50%) rotate(-45deg);
      content: '';

      @include breakpoint(map-get($breakpoints, portrait)) {
        position: absolute;
        top: rem(-20px);
        display: block;
        animation: check 0.5s linear;
        animation-fill-mode: backwards;
      }

      .highlight & {
        border-color: lighten(map-get($colors, green), 20);
      }
    }

    // arrow
    &::after {
      position: absolute;
      top: 100%;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      border-left: rem(5px) solid transparent;
      border-right: rem(5px) solid transparent;
      border-top: rem(5px) solid map-get($colors, blueKista);
      transform: translate(-50%, -50%);
      content: '';

      @include breakpoint(map-get($breakpoints, portrait)) {
        top: 50%;
        left: 100%;
        border-right: none;
        border-top: rem(5px) solid transparent;
        border-bottom: rem(5px) solid transparent;
        border-left: rem(5px) solid map-get($colors, blueKista);
        transform: translate(100%, -50%);
      }

      .highlight & {
        border-top-color: white;

        @include breakpoint(map-get($breakpoints, portrait)) {
          border-top-color: transparent;
          border-left-color: white;
        }
      }
    }
  }

  &__date {
    display: block;
    margin-top: rem(10px);
    font-size: rem(14px);
    font-family: map-get($font, body);
    text-transform: none;
  }
}
